.beta table{
    border:1px solid grey;
    
}

.beta th{

margin-bottom: 10px;
border:1px solid grey;
padding:10px;
text-align:center;

}

.beta td{
margin-bottom: 10px;
border:1px solid grey;
padding:10px;
text-align:center;


}